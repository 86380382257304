@keyframes ANIM-SMOOTING {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.smooting {
    animation: 1s ANIM-SMOOTING;
    width: 100%;
}